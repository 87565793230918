<template>
  <div class="feedBack">
    <img src="../../assets/my/kf@2x.png" alt />
    <p>任何问题，请添加客服微信，我们将第一时间为您解决。</p>
  </div>
</template>

<script>
export default {
  name: "feedBack",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.feedBack {
  padding: 15px 15px 0;
  height: 100vh;
  background: #f8f8f8;
  p {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 400;
    color: #777777;
    line-height: 18px;
  }
}
</style>
